import React from "react"
import { Link } from "gatsby"

const Sidebar = props => {
  return (
    <div>
      {Object.keys(props.category).map((category, i) => {
        const linkList = props.category[category].map((link, i) => {
          return (
            <li key={i}>
              <Link
                to={`/${props.subjectLink}${link.node.fields.slug}`}
                className="text-decoration-none"
              >
                {link.node.frontmatter.title}
              </Link>
            </li>
          )
        })
        return <div key={i}>{linkList}</div>
      })}
    </div>
  )
}

export default Sidebar
