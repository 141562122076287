import React from "react"
import { Link } from "gatsby"
import useIntersectNode from "../../utils/useIntersectionObserver"

const TocEntry = ({ items, active, depth = 0 }) => {
  return (
    <ol style={{ marginLeft: "0px" }}>
      {items.map(item => {
        const className =
          item.url === `#${active}`
            ? "text-decoration-none fw-bold text-secondary"
            : "text-decoration-none"

        const style = item.url === `#${active}` ? { fontSize: "1rem" } : null

        return (
          <React.Fragment key={item.url}>
            <li key={item.url}>
              <Link to={item.url} className={className} style={style}>
                {item.title}
              </Link>
            </li>
            <div>
              {item.items && (
                <TocEntry
                  items={item.items}
                  active={active}
                  depth={depth + 1}
                />
              )}
            </div>
          </React.Fragment>
        )
      })}
    </ol>
  )
}

const getTocEntries = items => {
  let entries = []
  items.forEach(item => {
    item.url && entries.push(item.url)
    item.items && entries.push(...getTocEntries(item.items))
  })
  return entries
}

const Toc = ({ items }) => {
  const itemsWithIntroduction = [...items]
  const [activeNode] = useIntersectNode(
    getTocEntries(itemsWithIntroduction),
    `0% 0% -85% 0%`,
    1
  )

  return <TocEntry items={itemsWithIntroduction} active={activeNode} />
}

export default Toc
