import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FaArrowDown, FaArrowUp, FaFolder, FaFolderOpen } from "react-icons/fa"

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 10px;
  padding: 10px;
  align-items: center;
  grid-template-columns: 5fr 90fr 5fr;

  > span0 {
    font-size: 18px;
    display: flex;
    justify-content: center;
  }

  span1 {
    font-size: 18px;
  }
  span2 {
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
`

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.onClick(this.props.label)
  }

  render() {
    const {
      onClick,
      props: { isOpen, label },
    } = this

    return (
      <div
        className="card border-primary shadow mb-2"
        style={{
          backgroundColor: "#" + Math.random().toString(16).slice(-6) + "10",
        }}
      >
        <div
          className="card-header p-0 border-0"
          onClick={onClick}
          role="presentation"
        >
          <Pagination>
            <span0 className="text-primary">
              {isOpen ? <FaFolderOpen /> : <FaFolder />}
            </span0>
            <span1 className="fw-bold text-primary" type="button">
              {label}
            </span1>
            <span2 className="text-primary">
              {isOpen ? <FaArrowUp /> : <FaArrowDown />}
            </span2>{" "}
          </Pagination>
        </div>
        {isOpen && (
          <div className="card-body">
            <ol className="card-text" style={{ marginLeft: "0px" }}>
              {this.props.children}
            </ol>
          </div>
        )}
      </div>
    )
  }
}

export default AccordionSection
