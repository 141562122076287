import React, { useState } from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { graphql, Link } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import styled from "styled-components"
import Accordion from "../components/Sidebar/Accordion"
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"
import Sidebar from "../components/Sidebar/Sidebar"
import useDarkMode from "use-dark-mode"
import parseCategoryData from "../utils/parseCategoryData"
import Toc from "../components/Toc/Toc"

const Wrap = styled.div`
  max-height: 77vh;
  display: flex;
`

const InnerScroll = styled.div`
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`

const Pagination = styled.div`
  height: auto;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;

  > a {
    text-decoration: none;
    border-radius: 4px;
    background: ${props => (props.dark ? "#2c2c2c" : "#eaeaea")};
    &:nth-child(2) {
      text-align: right;
    }
    span {
      color: #4e4e55;
      font-size: 16px;
      text-transform: uppercase;
    }
    h3 {
      font-size: 22px;
      font-weight: 700;
      line-height: 27px;
      margin-top: 20px;
    }
  }
  > a:hover {
    background: ${props => (props.dark ? "#3c3c3c" : "#fafafa")};
    border: 1px solid red !important;
    box-shadow: 0 15px 25px 0 rgba(0, 0, 0, 0.3) !important;
  }
`

const AIPost = ({ data, pageContext }) => {
  const { mdx } = data
  const { prev, next } = pageContext
  const subjectCategories = parseCategoryData(data.allMdx.edges)
  const [ToggleSidebar, setToggleSidebar] = useState(true)
  const toggle = () => setToggleSidebar(!ToggleSidebar)
  const darkMode = useDarkMode(false)

  return (
    <Layout>
      <Seo title={mdx.frontmatter.title} />
      <section className="subject-section">
        <div
          className="card-body shadow"
          style={{
            backgroundColor: "#" + Math.random().toString(16).slice(-6) + "50",
          }}
        >
          <div className="row justify-content-center align-items-center mb-4">
            <div className="col-lg-9">
              <p
                className="badge bg-primary text-light text-decoration-none shadow"
                style={{ fontSize: "0.9em" }}
              >
                {mdx.frontmatter.folder}
              </p>
            </div>
            <div className="col-lg-3">
              <Pagination dark={darkMode.value}>
                {prev ? (
                  <Link
                    to={`/artificial_intelligence${prev.node.fields.slug}`}
                    className="btn text-center border-primary shadow"
                  >
                    <span className="text-primary">
                      <FaArrowLeft /> Previous
                    </span>
                  </Link>
                ) : (
                  <div></div>
                )}
                {next ? (
                  <Link
                    to={`/artificial_intelligence${next.node.fields.slug}`}
                    className="btn text-center border-primary shadow"
                  >
                    <span className="text-primary">
                      Next <FaArrowRight />
                    </span>
                  </Link>
                ) : (
                  <div></div>
                )}
              </Pagination>
            </div>
          </div>
          <h1 className="fat-text">{mdx.frontmatter.title}</h1>
        </div>
        <div className="container-fluid py-5">
          <div className="row justify-content-center">
            {ToggleSidebar && (
              <div className="col-lg-3 mb-4" id="sticky-sidebar">
                <div
                  className="card border-primary shadow sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Chapters</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Accordion allowMultipleOpen>
                        {Object.keys(subjectCategories).map((category, i) => {
                          return (
                            <div key={i} label={category} isOpen>
                              <Sidebar
                                subjectLink={`artificial_intelligence`}
                                category={subjectCategories[category]}
                                target="ToggleSidebar"
                              />
                            </div>
                          )
                        })}
                      </Accordion>
                    </InnerScroll>
                  </Wrap>
                </div>
              </div>
            )}
            <div
              className="col-lg-3 col-md-12 col-sm-12 mb-4 order-md-2"
              id="sticky-sidebar"
            >
              {typeof mdx.tableOfContents.items === "undefined" ? null : (
                <div
                  className="card border-primary shadow sticky-top"
                  style={{
                    backgroundColor:
                      "#" + Math.random().toString(16).slice(-6) + "10",
                  }}
                >
                  <div className="card-header text-center">
                    <h5 className="fat-text">Table of Content</h5>
                  </div>
                  <Wrap>
                    <InnerScroll className="card-body small no-scrollbar">
                      <Toc items={mdx.tableOfContents.items} />
                    </InnerScroll>
                  </Wrap>
                </div>
              )}
            </div>
            <div className={ToggleSidebar ? "col-lg-6 mb-4" : "col-lg-9 mb-4"}>
              <div
                className="card border-primary shadow mb-4"
                style={{
                  backgroundColor:
                    "#" + Math.random().toString(16).slice(-6) + "10",
                }}
              >
                <div className="card-header">
                  <button
                    onClick={toggle}
                    id="ToggleSidebar"
                    className="btn btn-outline-primary btn-sm"
                  >
                    <FaArrowLeft /> Toggle Sidebar
                  </button>
                </div>
                <div className="card-body">
                  <MDXRenderer>{mdx.body}</MDXRenderer>
                </div>
              </div>
              <Pagination dark={darkMode.value}>
                {prev ? (
                  <Link
                    to={`/artificial_intelligence${prev.node.fields.slug}`}
                    className="btn text-center border-primary shadow"
                    style={{
                      backgroundColor:
                        "#" + Math.random().toString(16).slice(-6) + "10",
                    }}
                  >
                    <span className="text-primary">
                      <FaArrowLeft /> Previous
                    </span>
                    <h3 className="text-secondary">
                      {prev.node.frontmatter.title}
                    </h3>
                  </Link>
                ) : (
                  <div></div>
                )}
                {next ? (
                  <Link
                    to={`/artificial_intelligence${next.node.fields.slug}`}
                    className="btn text-center border-primary shadow"
                    style={{
                      backgroundColor:
                        "#" + Math.random().toString(16).slice(-6) + "10",
                    }}
                  >
                    <span className="text-primary">
                      Next <FaArrowRight />
                    </span>
                    <h3 className="text-secondary">
                      {next.node.frontmatter.title}
                    </h3>
                  </Link>
                ) : (
                  <div></div>
                )}
              </Pagination>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AIPost

export const query = graphql`
  query AIPostBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      id
      fields {
        slug
      }
      tableOfContents
      frontmatter {
        folder
        title
        date(formatString: "DD MMMM, YYYY")
      }
    }
    allMdx(
      filter: {
        fileAbsolutePath: { regex: "/(artificial_intelligence)/.*.(mdx)/" }
      }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
            topLevelDir
            subDir
          }
        }
      }
    }
  }
`
